<template>
  <div>
    <b-row class="match-height">

      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="danger"
          icon="CpuIcon"
          statistic="1"
          statistic-title="总数量"
        />
      </b-col>

      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          icon="MapIcon"
          statistic="1"
          statistic-title="进行中"
          color="info"
        />
      </b-col>

      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          icon="BookIcon"
          statistic="0"
          statistic-title="已完成"
          
        />
      </b-col>

    </b-row>

    <b-card>
    <!-- search & new -->
    <b-row>
      <b-col cols="3">
        <b-form-select
          placeholder="数据分类"
          v-model="query_catalog"
          :options="catalogOptions"
        />
      </b-col> 
       <b-col cols="3">
        <b-form-select
          placeholder="数据分级"
          v-model="query_level"
          :options="levelOptions"
        />
      </b-col>  
      <b-col cols="3">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="query_keyword"
            placeholder="名称、描述"
            @keypress.enter="doSearch"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="cursor-pointer"
              @click="doSearch"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-button variant="primary" @click="doSearch">
          <feather-icon icon="RefreshIcon" class="mr-50" />
          <span class="align-middle">刷新</span>
        </b-button>       
      </b-col>
    </b-row>

    <!-- list -->
    <b-row>
      <!--data list--->
      <b-col cols="12">
        <b-table
          ref="listTable"
          :items="listTableItems"
          :fields="listTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          striped
          hover
          responsive
          :per-page="listTablePerPage"
          :current-page="listTableCurrentPage"
          class="mt-1"
          show-empty
          empty-text="没有找到符合要求的数据"
        >
          <template #cell(manager)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.manager.avatar"
                  :text="data.item.manager.name"
                  variant="light-primary"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.manager.name }}
              </span>
              <small class="text-muted">{{ data.item.manager.email }}</small>
            </b-media>
          </template>

          <template #cell(actions)="data">
            <div class="text-right text-nowrap">
              <b-button
                variant="flat-success"
                class="btn-icon"
                @click="viewItem(data)"
                ><feather-icon icon="EyeIcon"
              /></b-button>
            </div>
          </template>
        </b-table>
      </b-col>

      <!--pagination--->
      <b-col cols="12">
        <b-pagination
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
  BMedia,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BMedia,
    StatisticCardVertical,
  },

  data() {
    return {
      queryData: null,
      // select options
      catalogOptions: [
        { value: "", text: "DataMap类型" },
        "公司级",
        "部门级",
        "系统级",
        "其他",
      ],
      levelOptions: [
        { value: "", text: "进度" },
        "进行中",
        "已完成",
      ],

      // query
      query_catalog: "",
      query_level: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      sortBy: "index",
      sortDesc: false,
      listTableFields: [
        { key: "id", label: "序号" },
        { key: "title", label: "名称" },
        { key: "summary", label: "简介" },
        { key: "ownerName", label: "发起人" },
        { key: "start", label: "开始日期" },
        { key: "end", label: "结束日期" },
        { key: "progress", label: "进度" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 10,
      listTablePageOptions: [10, 15, 30],
      listTableTotalRows: 10,
      listTableCurrentPage: 1,

      // edit
      currentItem: null,
      baseInfoVisible: true,
      adminUser: null,
      showEdit: false,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData : [];
    },
  },

  mounted() {
    this.doSearch();
  },

  methods: {
    doSearch() {
      // debugger
      this.$http
        .get("/datamaps").then((res) => {
          this.queryData = res.data.data.list;
          this.listTableTotalRows = res.data.data.total;
        });
    },

    onEditHidden() {
      this.showEdit = false;
    },
  },
};
</script>